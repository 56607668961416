const QRCode = require("qrcode");

const Config = require("../Config/Config");
const ConfigurationFactory = require("../ConfigurationFactory");
const Global = require("../Config/Global");
const Logger = require("../Logger");

const ContentSentinelDataProvider = require("../Data/ContentSentinelDataProvider");
const PlayerIntegration = require("../Player/PlayerIntegration");
const WebPlayerRegistrationUIController = require("./WebPlayerRegistrationUIController");

const SECONDS = 1000;
const DAYS = 24 * 60 * 60 * SECONDS;

let initialized = false;

let activateEl = null;
let activationDigits = null;
let activationQr = null;
let activationCodeExpirationTimeout = null;

function getNewActivationCode() {
  return fetch(Global.ACTIVATION_CODE_URL)
  .then(resp => {
    if (resp.ok) {
      return resp.json();
    } else {
      return Promise.reject(`Failed to retrieve activation code. Status: ${resp.status}`);
    }
  }).then(json => {
    if (json && json.displayId && json.activationKey) {
      console.log("Request Display activation code - success");
      return json;
    } else {
      return Promise.reject(`Invalid activation code response: ${json}`);
    }
  }).catch(err => {
    console.log("Request Display activation code - fail", err);
    return Promise.reject(err);
  });
}

function storeActivationCode(resp) {
  localStorage.setItem(ConfigurationFactory.WEBPLAYER_DISPLAY_ID_KEY, resp.displayId);
  localStorage.setItem(ConfigurationFactory.WEBPLAYER_ACTIVATION_CODE, resp.activationKey);
  localStorage.setItem(ConfigurationFactory.WEBPLAYER_ACTIVATION_CODE_TIMESTAMP, Date.now());

  return resp;
}

function shouldRequestNewCode(codeTimestamp) {
  const now = Date.now();
  const almostExpiredInterval = 6.5 * DAYS;

  return now - codeTimestamp > almostExpiredInterval;
}

function setActivationCodeExpirationTimeout() {
  const codeTimestamp = ConfigurationFactory.getActivationCodeTimestamp();
  const now = Date.now();

  const expiringTimestamp = codeTimestamp + 6.8 * DAYS;
  const restartTimeout = expiringTimestamp - now;

  if (restartTimeout <= 0) {
    console.error( `Illegal restart timeout value: ${ restartTimeout }` );
    return;
  }

  if (activationCodeExpirationTimeout) {
    clearActivationCodeExpirationTimeout();
  }

  console.warn( `Player will restart in ${ restartTimeout } ms if QR code is not activated.` );

  activationCodeExpirationTimeout = setTimeout( () =>
    PlayerIntegration.requestRestart(false), restartTimeout );
}

function clearActivationCodeExpirationTimeout() {
  if( !activationCodeExpirationTimeout ) {
    return;
  }

  console.log( "Clearing activation code expiration timeout" );
  clearTimeout( activationCodeExpirationTimeout );
  activationCodeExpirationTimeout = null;
}

function getActivationCode() {
  const savedCode = ConfigurationFactory.getActivationCode();
  const codeTimestamp = ConfigurationFactory.getActivationCodeTimestamp();
  let codePromise = Promise.resolve(savedCode);

  if (!savedCode || shouldRequestNewCode(codeTimestamp)) {
    codePromise = getNewActivationCode()
      .then(storeActivationCode)
      .then(resp => resp.activationKey);
  }

  return codePromise.then(activationCode => {
    setActivationCodeExpirationTimeout();
  
    return activationCode;
  });
}

function displayActivationCode(code) {
  let codeStr = String(code);
  let digits = "0".repeat(5 - codeStr.length) + codeStr;

  for (var i = 0; i < 5; i++) {
    activationDigits[i].innerHTML = `<span>${digits[i]}</span>`;
  }
}

function generateUrlQrCode(code) {
  let baseUrl = Config.APPS_URL;
  let appsUrl = `${baseUrl}/activate/${code}`;
  let qrOptions = {
    width: 512
  };

  return QRCode.toCanvas(activationQr, appsUrl, qrOptions)
    .then(res => console.log("Successfully created QR", res))
    .catch(err => console.log("Failed to create QR", err));
}

function shouldUseMinimalActivationUI() {
  return ConfigurationFactory.isFireOSPlayer();
}

function showWebPlayerActivationUI(entryPointFn) {
  const isActivationMinimal = shouldUseMinimalActivationUI();
  activateEl = document.querySelector("#webplayer-activation"); 

  if (initialized) {
    activateEl.style.display = "flex";
    return;
  }

  initialized = true;

  if (isActivationMinimal) {
    document.getElementsByClassName("activate-divider")[0].style.display = 'none'
    document.getElementsByClassName("activate-qr")[0].style.display = 'none'
    document.getElementById("activate-signin-step").style.display = 'none'
    document.getElementsByClassName("wpa-footer")[0].style.display = 'none'
  }

  activationDigits = activateEl.querySelectorAll(".code-digit");
  activationQr = activateEl.querySelector(".qr-container").querySelector("canvas");

  const activateButtonEl = activateEl.querySelector(".activate-button");

  activateButtonEl.addEventListener("click", ()=>{
    activateEl.style.display = "none";

    WebPlayerRegistrationUIController.showWebPlayerRegistrationUI(entryPointFn, showWebPlayerActivationUI);
  });

  getActivationCode()
    .then(code => {
      displayActivationCode(code);

      if (isActivationMinimal) {
        return;
      }

      return generateUrlQrCode(code);
    })
    .then(() => {
      activateEl.style.display = "flex";
      entryPointFn();
    })
    .catch(err => {
      console.log("Error while initializing activation screen", err);
      throw err;
    });
}

function hideWebPlayerActivationUI() {
  activateEl = document.querySelector("#webplayer-activation");
  activateEl.style.display = "none";
}

function waitUntilContentJsonExists(entryPointFn) {
  return new Promise((resolve, reject) => {
    let initialDisplayId = ConfigurationFactory.getDisplayId();

    ContentSentinelDataProvider.onUpdate(initialDisplayId, (file, unsubscribe) => {
      if (ContentSentinelDataProvider.isFileCurrent(file)) {
        let activationCode = ConfigurationFactory.getActivationCode();
        let currentDisplayId = ConfigurationFactory.getDisplayId();
        let displayIdChanged = initialDisplayId !== currentDisplayId;

        PlayerIntegration.flushLocalStorageToDisk();

        unsubscribe();

        if (activationCode) {
          Logger.log("Display activated");
          ConfigurationFactory.removeActivationCode();
          clearActivationCodeExpirationTimeout();
        }

        if (displayIdChanged) {
          Logger.log("Display manually registered");
          PlayerIntegration.requestRestart();
          reject();
        } else {
          listenForContentJsonDeletion();
          hideWebPlayerActivationUI();
          resolve();
        }
      } else if (!ContentSentinelDataProvider.isFileStale(file)) {
        Logger.logDebug(`Waiting for display activation: ${file.status}`);
        showWebPlayerActivationUI(entryPointFn);
      }
    });
  });
}

function listenForContentJsonDeletion() {
  ContentSentinelDataProvider.onDelete(ConfigurationFactory.getDisplayId(), (file) => {
    Logger.log("Display deleted");
    localStorage.clear();

    // Give time to flush storage.
    setTimeout(() => PlayerIntegration.requestRestart(), 2 * SECONDS);
  });
}

module.exports = {
  shouldUseMinimalActivationUI,
  showWebPlayerActivationUI,
  hideWebPlayerActivationUI,
  waitUntilContentJsonExists,
  getActivationCodeExpirationTimeout: () => activationCodeExpirationTimeout,
  reset: () => { initialized = false }
};
