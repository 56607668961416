const ViewerDataInfo = require("../Data/ViewerDataInfo");

const service = {};
const ScreenSharingState = {
  INACTIVE: 0,
  ACTIVE: 1,
  SHARING: 10,
  PRIORITY_CONTENT: 20
};

let state = ScreenSharingState.INACTIVE;
let joinCode, connectionStatus;

service.setJoinCode = (code) => {
  joinCode = code;
  connectionStatus = null;
  _toggleState(ScreenSharingState.ACTIVE);
};

service.setConnectionStatus = (message) => {
  connectionStatus = message || 'Unknown error';
  joinCode = null;
  _toggleState(ScreenSharingState.ACTIVE);
};

service.setScreenSharingPlaying = (screenSharing) => {
  _toggleState(ScreenSharingState.SHARING, screenSharing);
};

service.setPriorityContentPlaying = (priorityContent) => {
  _toggleState(ScreenSharingState.PRIORITY_CONTENT, priorityContent);
};

service.reset = () => {
  joinCode = null;
  connectionStatus = null;

  _toggleState(ScreenSharingState.ACTIVE, false);
};

const _showJoinCode = (show) => {
  const div = document.querySelector("#join-code-overlay");
  const innerDiv = document.querySelector("#join-code");
  if (div && innerDiv) {
    innerDiv.innerHTML = joinCode || '';
    div.style.display = show ? "flex" : "none";
  }
};

const _showConnectionStatus = (show) => {
  const div = document.getElementById("screen-sharing-error-overlay");
  const msgDiv = document.getElementById("screen-sharing-error-message");
  if (div) {
    msgDiv.innerHTML = connectionStatus || '';
    div.style.display = show ? "flex" : "none";
  }
};

const _toggleState = (newState, value = true) => {
  if (newState === ScreenSharingState.INACTIVE && value) {
    // Not used - Setting Inactive state
    state = newState;
  } else if (newState < state) {
    // Lower priority state ignored
    return;
  } else if (newState >= state && value) {
    // Higher priority (or same) state set
    state = newState;
  // Priority content (false) messages come in all the time, ignore while SHARING
  } else if (newState === ScreenSharingState.PRIORITY_CONTENT && state !== ScreenSharingState.SHARING) {
    state = (joinCode ? ScreenSharingState.ACTIVE : ScreenSharingState.INACTIVE);
  } else if (newState === ScreenSharingState.SHARING) {
    state = ScreenSharingState.ACTIVE;
  } else if (newState === ScreenSharingState.ACTIVE) {
    state = ScreenSharingState.INACTIVE;
  }

  service.refresh();
};

service.refresh = () => {
  console.log('Screen Sharing refresh called:', state, joinCode, connectionStatus);

  if (!ViewerDataInfo.getDisplayData().screenSharingEnabled) {
    state = ScreenSharingState.INACTIVE;
  }

  if (state === ScreenSharingState.ACTIVE) {
    _showConnectionStatus(!!connectionStatus);
    _showJoinCode(!connectionStatus);
  } else {
    _showConnectionStatus(false);
    _showJoinCode(false);
  }
};

module.exports = service;
