const Logger = require("../Logger");
const ConfigurationFactory = require("../ConfigurationFactory");
const ViewerDataInfo = require("../Data/ViewerDataInfo");
const Utils = require("../Utils");
const Config = require("../Config/Config");


const service = {};
const HTML_TEMPLATE_PARAMS = "?cid=CID&id=DID&presentationId=PID&frameElementId=FRAMEID&type=TYPE&viewerId=VIEWERID&screenSharingMode=SSMODE&screenSharingPinRequired=SSPIN&memberDisplayName=DNAME";

service.render = (htmlName, containerName) => {
  const width = ConfigurationFactory.getWindowWidth();
  const height = ConfigurationFactory.getWindowHeight();
  const top = 0, left = 0;

  Logger.logDebug(`ScreenSharingRenderer rendering ${htmlName} into container ${containerName}`);

  if (!htmlName || !containerName) {
    return;
  }

  let url = Config.SCREEN_SHARING_URL + HTML_TEMPLATE_PARAMS
    .replace("DID", ConfigurationFactory.getId())
    .replace("CID", ConfigurationFactory.getCompanyId())
    .replace("PID", "SCREEN_SHARING")
    .replace("FRAMEID", "iFrame_" + htmlName)
    .replace("TYPE", ConfigurationFactory.getType())
    .replace("VIEWERID", Utils.getUniqueId())
    .replace("SSMODE", ViewerDataInfo.getScreenSharingMode())
    .replace("SSPIN", ViewerDataInfo.getScreenSharingPinRequired())
    .replace("DNAME", ConfigurationFactory.getDisplayName());

  window.createURL(htmlName, containerName, width, height, top, left, 0, 0, url);

  RiseVision.Viewer.UI.ViewerZoomController.init(htmlName, width, height);
};

module.exports = service;
